var GAEvents = GAEvents || {};

GAEvents.send = function(category, action, label, value) {
    var getTrackingString = function(str) {
        return (true) ? str : 'TEST[' + str + ']';
    };

    var val = value || 0;
    var lab = label || '';

    try {
        gtag('event', action, {
            'event_category': category,
            'event_label': lab,
            'value': val
        });
    } catch(e){ }
};