
/*
	Execute the count-up in conjunction with waypoints when they are scrolled to.
*/

jQuery(document).ready(function( $ ) {

	var $count = $('.count'),
		$caraousel = $('.carousel'),
		$quoteForm = $('#quote');

	if ($count.length) {
		$count.counterUp({
			delay: 10,
			time: 1000
		});
	}
	
	if($caraousel.length) {
		$caraousel.slick({
			dots: false,
			infinite: true,
			adaptiveHeight: true
		});
	}

	if($quoteForm.length) {
		$quoteForm.quoteForm();
	}

	// Quote tab sliding in and out on scroll
	var waypoint = new Waypoint({
		element: document.getElementById('content'),
		handler: function(direction) {
			$('.quote-tab')[(direction == 'down' ? 'add' : 'remove') + 'Class']('in');
		},
		offset: 100
	});

	// Sticky nav shrink trigger
	$(window).on('scroll', function() {
		$('#masthead')[($(window).scrollTop() > $('.nav-orange').outerHeight() ? 'add' : 'remove') + 'Class']('sticky');
	});


	//
	//  In page form validation
	// ---------------------------------------
	$('#general-enquiry').formValidator({
		definitions: [
			{
				target: '#full_name',
				rules: {
					required: {
						value: true,
						message: 'Please enter your name'
					}
				}
			},
			{
				target: '#phone',
				rules: {
					required: {
						value: true,
						message: 'Please enter your phone number'
					}
				}
			},
			{
				target: '#email',
				rules: {
					required:
					{
						value: true,
						message: 'Please enter your phone number'
					},
					email:
					{
						value: true,
						message: 'Please enter a valid email address'
					}
				}
			},
			{
				target: '#enquiry_type',
				rules: {
					required: {
						value: true,
						message: 'Please select an enquiry type'
					}
				}
			},
			{
				target: '#comments',
				rules: {
					required: {
						value: true,
						message: 'Please give us your enquiry details'
					}
				}
			}
		]
	});

	$('#request-callback').formValidator({
		definitions: [
			{
				target: '#full_name',
				rules: {
					required: {
						value: true,
						message: 'Please enter your name'
					}
				}
			},
			{
				target: '#phone',
				rules: {
					required: {
						value: true,
						message: 'Please enter your phone number'
					}
				}
			},
			{
				target: '#email',
				rules: {
					required:
					{
						value: true,
						message: 'Please enter your phone number'
					},
					email:
					{
						value: true,
						message: 'Please enter a valid email address'
					}
				}
			},
			{
				target: '#postcode',
				rules: {
					required: {
						value: true,
						message: 'Please enter your postcode'
					}
				}
			}
		]
	});

	//
	//  Track Click-to-call
	// ---------------------------------------
    $('a[href^="tel:"]').on('click', function (e) {
    	if (window.ga === undefined)
    		return;

    	var eventCategory = 'Click to call';
    	var eventAction = '';
    	var eventLabel = '';
    	var $innerNumber = $(this).find('.js-phone-inner');

		//
		//  Where the phone click is
		// ---------------------------------------
		if ($(this).is('.navbar-btn')) {
            // Top header
            eventAction = 'Click in header';

		} else if ($(this).closest('.contact-info').length) {
            // Contact location info
            eventAction = 'Click in contact location info';

		} else if ($(this).closest('.footer-column-nav').length) {
            // Footer
            eventAction = 'Click in footer';

		} else if ($(this).closest('.form-section').length) {
            // Quote form
            eventAction = 'Click in quote form';

            var $slide = $(this).closest('.slick-slide');

            if ($slide.length) {
                eventAction += ' - Step ' + (Number($slide.data('slick-index')) + 1);
			}
		} else {
            eventAction = 'Click in content';
		}

		//
		//  The number that was clicked
		// ---------------------------------------
		if ($innerNumber.length) {
            eventLabel = $innerNumber.text();
		} else {
            eventLabel = $(this).attr('href').split('tel:').join('');
		}

		//
		//  The page path where the click occurred
		// ---------------------------------------
        eventLabel += ' - ' + window.location.pathname;

		// Since GTM exists, need to select a GA contianer for custom even to log
		gtag('event', eventAction, {
			'event_category': eventCategory,
			'event_label': eventLabel
		});
    });

    $(".quote-tab").click(function() {
        scrollToAnchor('quote');
    });

    var $enquiryForm = $("form#general-enquiry");
    if($enquiryForm.length) {
    	var interactionStarted = false;

        $enquiryForm.find('.form-control').on('keyup.track', function(e) {
            if(!interactionStarted) {
                GAEvents.send('General Enquiry Form', 'Enquiry Started', $('#enquiry_type').val());
                interactionStarted = true;
                $enquiryForm.find('.form-control').off('keyup.track');
            }
        });

        $enquiryForm.find('[type="submit"]').on('click', function(e){
            GAEvents.send('General Enquiry Form', 'Intent to submit', $('#enquiry_type').val());
        });
	}
});

/* Animated scrolling to the quote form */
function scrollToAnchor(aid){
    var aTag = $("#"+ aid);
    $('html,body').animate({scrollTop: (aTag.offset().top || 0)},'slow');
}


// Add format to the String prototype
String.prototype.format = function() {
	var s = this,
		i = arguments.length;

	while (i--) {
		s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), arguments[i]);
	}
	return s;
};
