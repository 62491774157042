/**
 * Module that validates a form on submit
 *
 * Rule definitions are provided in the following format:
 *
 * definitions: [{
 *     target:    '#phone-number',          << REQUIRED. CSS Selector of the input being validated OR an array of selectors
 *     name:      'Phone number',           << OPTIONAL. Name of the field to put in error messages
 *     container: '#field-container',       << OPTIONAL. CSS selector of the container which will house the error list.
 *                                                       If not specified it will look for a parent of the input with the class settings.fieldContainerClass
 *     rules: {                             << REQUIRED. An object representing all of the rules for the given input
 *          maxlength: 10,                  << Values can be passed like this OR
 *          minlength: {                    << values can be represented by an object
 *              value: 3,
 *              message: 'Too short bro'    << OPTIONAL. Override the error message
 *          }
 *     }
 * }]
 */
(function ($, window, document, undefined) {
    'use strict';

    var pluginName = 'formValidator',
        defaults = {
            fieldContainerClass: 'form-group',
            fieldErrorClass:     'has-error error-alert',
            fieldErrorListClass: 'form-error list-unstyled',
            scrollToError:       true,

            definitions: []
        },

        _validators = {
            'required': function ($fields, allFieldsRequired, fieldName) {
                var valid = allFieldsRequired;

                for (var i = 0, len = $fields.length; i < len; i++) {
                    var $field = $fields.eq(i),
                        //fieldHasValue = ($field.is(':checkbox') || $field.is(':radio')) ?
                        fieldHasValue = ($field.is('input[type="checkbox"]') || $field.is('input[type="radio"]')) ?
                            $field.is(':checked') :
                            ($field.is('select') && $field.val() === allFieldsRequired) ?
                                false :
                            !!$field.val();

                    // If all fields are required and one is falsy return false
                    if (allFieldsRequired && !fieldHasValue) {
                        valid = false;
                        break;
                    }

                    // If only one field is required and one is found to be truthy, return true
                    if (allFieldsRequired === false && fieldHasValue) {
                        valid = true;
                        break;
                    }
                }

                return {
                    valid: valid,
                    invalidMessage: '{0} is required'.format(fieldName || 'Field')
                };
            },
            'email': function ($fields) {
                var valid = true,
                    emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;

                for (var i = 0, len = $fields.length; i < len; i++) {
                    var $field = $fields.eq(i);

                    if (!emailPattern.test($field.val())) {
                        valid = false;
                    }
                }

                return {
                    valid: valid,
                    invalidMessage: 'Email address entered is not valid'
                };
            }
        };

    function Plugin (element, options) {
        var _this = this;
        _this.element  = element;
        _this.$form    = $(element);
        _this.errors   = [];
        _this.settings = $.extend({}, defaults, options);

        _this.init();
    }

    $.extend(Plugin.prototype, {

        // --- Public functions ---

        // Initialise the plugin
        'init': function () {
            var _this    = this,
                acceptedInputTypes = ['text', 'email', 'password', 'number', 'tel'];  // If validator is attached to a single field to be triggered on 'blur'

            if (_this.$form.is('form')) {
                _this.$form.on('submit', function (event) {
                    var result = false;

                    try {
                        result = _this.validate(event);
                    }
                    catch (e) {
                        event.preventDefault();
                        throw e;
                    }

                    if (result === false) {
                        event.stopImmediatePropagation();
                        event.preventDefault();
                    }

                    event.result = result;
                    return result;
                });

            } else if(_this.$form.is('input') && $.inArray(_this.$form.attr('type'), acceptedInputTypes) > -1) {

                _this.$form.on('blur', function (event) {
                    var result = false;

                    try {
                        result = _this.validate(event);
                    }
                    catch (e) {
                        throw e;
                    }

                    event.result = result;
                    return result;
                });

            } else {

                throw 'Form Validator: Cannot attach validator if object is not a form or an input with type of ' + acceptedInputTypes.join(', ');

            }
        },

        // Remove rendered error lists from each field
        'clear': function (container) {
            var _this = this;
            for (var i = 0, defsLen = _this.settings.definitions.length; i < defsLen; i++) {

                var fieldDef   = _this.settings.definitions[i],
                    $fields    = _this._getField(fieldDef.target, container),
                    $errorList = _this._getFieldErrorList($fields, fieldDef.container);

                $fields.removeClass(_this.settings.fieldErrorClass);
                $errorList.remove();
            }
        },

        // Update definition
        'update': function (newDefinition, overwrite) {
            var _this = this;

            // Find the definition for the target specified
            for (var i = _this.settings.definitions.length - 1; i >= 0; i--) {
                var existingDefinition = _this.settings.definitions[i];
                if (newDefinition.target !== existingDefinition.target) { continue; }

                for (var rule in newDefinition.rules) {
                    if (!existingDefinition.rules.hasOwnProperty(rule) || overwrite) {
                        existingDefinition.rules[rule] = newDefinition.rules[rule];
                    }
                }

                return;
            }

            // Target could not be found in the definition collection, so add it
            _this.settings.definitions.push(newDefinition);
        },

        // Validate the form against the rules defined and render any errors found. Returns a boolean indicating validity
        'validate': function (event) {
            var _this            = this,
                defs             = _this.settings.definitions,
                validationPassId = 'pass_' + Date.now(),
                errorsFound      = false,
                scrolledToError  = !_this.settings.scrollToError;

            for (var i = 0, defsLen = defs.length; i < defsLen; i++) {

                var fieldDef = defs[i];

                if (!fieldDef.target && (typeof event === 'undefined' || event.type !== 'blur')) {
                    throw 'Form Validator: target must be defined';
                }

                var $fields;
                if (typeof event !== 'undefined' && event.type === 'blur') {
                    $fields = _this.$form;
                } else {
                    $fields = _this._getField(fieldDef.target);
                }

                if ($fields.length === 0 || $fields.first().length === 0 || ($fields.first().is(':visible') === false && (typeof fieldDef.validateWhenHidden === "undefined" || !fieldDef.validateWhenHidden)) ) {
                    continue;
                }

                if (!fieldDef.name) {
                    // If not specified, grab the label of the first field in the group
                    fieldDef.name = _this._getFieldLabelValue($fields.first());
                }

                // Validate this field against each of the rules specified for it
                var errors = [];
                for (var rule in fieldDef.rules) {
                    if (fieldDef.rules.hasOwnProperty(rule) &&
                        typeof _validators[rule] === 'function') {

                        var ruleDef         = fieldDef.rules[rule],
                            validationValue = ruleDef,
                            errorMessage    = '',
                            dismissMessage  = '';

                        if (typeof ruleDef === 'object') {
                            if (ruleDef.hasOwnProperty('value')) {
                                validationValue = ruleDef.value;
                            }

                            if (ruleDef.hasOwnProperty('message')) {
                                errorMessage = ruleDef.message;
                            }

                            if (ruleDef.hasOwnProperty('dismissMessage')) {
                                dismissMessage = ruleDef.dismissMessage;
                            }
                        }

                        var result = _validators[rule]($fields, validationValue, fieldDef.name);

                        if (result.valid === false) {
                            errors.push({message: (errorMessage || result.invalidMessage), dismissMessage: (dismissMessage || null)});
                            errorsFound = true;
                        }
                    }
                }

                _this._renderValidation($fields, fieldDef.container, errors, validationPassId, !scrolledToError);

                if (errors.length) {
                    scrolledToError = true;
                }
            }

            return errorsFound === false;
        },

        // --- Private functions ---

        '_createErrorList': function ($fields, container, validationPassId) {
            var _this = this,
                $container = _this._getFieldContainer($fields, container),
                $errorList = $('<ul class="' + _this.settings.fieldErrorListClass + ' ' + validationPassId + ' show"></ul>');

            if ($container.length === 0) {
                $fields.first().after($errorList);
            } else {
                $container.append($errorList);
            }

            return $errorList;
        },

        '_getField': function (target, container) {
            if (target instanceof Array) {
                // Convert into comma separated string
                target = target.join();
            }

            // Filter by container if it is passed
            if (container) {
                return $(container).find(target);
            }

            return this.$form.find(target);
        },

        '_getFieldContainer': function ($fields, container) {
            var _this = this,
                $container = null;

            if (container) {
                $container = $(container);
                return $container.eq(0);
            }

            // Find the first container that is common to all fields
            $container = $fields.eq(0).parents('.' + _this.settings.fieldContainerClass);

            for(var i = 1, len = $fields.length; i < len; i++) {
                var $field = $fields.eq(i);

                $container = $container.has(window.Zepto ? $field[0] : $field);
            }

            return $container.first();
        },

        '_getFieldErrorList': function ($fields, container) {
            var _this = this,
                $container = _this._getFieldContainer($fields, container);

            if ($container.length > 0) {
                return $container.find('ul.' + _this.settings.fieldErrorListClass.split(" ").join("."));
            }

            return $fields.eq(0).siblings('ul.' + _this.settings.fieldErrorListClass.split(" ").join("."));
        },

        '_getFieldLabelValue': function ($field) {
            if (!$field[0].id) {
                return '';
            }

            var _this  = this,
                $label = _this.$form.find('label[for=' + $field[0].id + ']');

            if ($label.length === 1) {
                return $label.eq(0).text();
            }

            return '';
        },

        '_renderValidation': function ($fields, container, errors, validationPassId, scrollRequired) {
            var _this       = this,
                $errorList  = _this._getFieldErrorList($fields, container),
                currentPass = $errorList.hasClass(validationPassId);

            if (!currentPass) {
                $fields.removeClass(_this.settings.fieldErrorClass);

                // Need this to trigger redraw for css animation
                var redraw = $fields.height();
            }

            if (errors.length > 0) {

                // Scroll page so that errored field is in view
                if (scrollRequired) {
                    _this._scrollToField($fields.eq(0));
                }

                $fields.addClass(_this.settings.fieldErrorClass);

                if (!$errorList.length) {
                    $errorList = _this._createErrorList($fields, container, validationPassId);
                }

                if (!currentPass) {
                    $errorList.empty();
                    $errorList.addClass(validationPassId);
                }

                for (var i = 0, len = errors.length; i < len; i++) {
                    var li = $('<li></li>', {
                        'text': errors[i].message
                    });
                    if(errors[i].dismissMessage) {
                        var a = $('<a href="#" class="pull-right">'+errors[i].dismissMessage+'</a>').on('click', function(e){
                            e.preventDefault();
                            _this._removeError($fields, li);
                        });
                        li.append(a);
                    }
                    $errorList.append(li);
                }

                _this.$form.trigger('errorsRendered.formValidator');

            } else {
                if ($errorList.length && !currentPass) {
                    $errorList.remove();
                }
            }
        },

        '_scrollToField': function ($field) {
            var $window = $(window),
                $document = $(document),
                scrollSpeed = 600,
                fieldOffset = ($field.is(":visible") ? $field.offset().top : $field.parent().offset().top),
                offset = fieldOffset + $field.height() - $window.height() / 2,
                alwaysScroll = $field.attr("data-always-scroll-on-error");


            // Don't scroll if the element is in view already
            if (!alwaysScroll && (fieldOffset > $document.scrollTop() && fieldOffset < ($document.scrollTop() + $window.height()))) {
                return;
            }

            try {
                $('html, body').animate({scrollTop:offset}, scrollSpeed);
            } catch (e) {
                // well, we ain't got no animate module, so let's just snap there
                $('body').scrollTop(offset);
            }
        },

        '_removeError': function($fields, $li) {
            var _this = this,
                $errorUl = $li.parent();

            $li.remove();

            if(!$errorUl.has('li').length) {
                // no more errors, remove stuff
                $errorUl.remove();
                $fields.removeClass(_this.settings.fieldErrorClass);
            }
        }
    });

    $.fn[pluginName] = function (options) {
        var args = arguments;

        if (options === undefined || typeof options === 'object') {

            return this.each(function () {
                if (!$.data(this, 'plugin_' + pluginName)) {
                    $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
                }
            });

        } else if (typeof options === 'string' && options[0] !== '_' && options !== 'init') {

            var returns;
            this.each(function () {
                var instance = $.data(this, 'plugin_' + pluginName);
                if (instance instanceof Plugin && typeof instance[options] === 'function') {
                    returns = instance[options].apply(instance, Array.prototype.slice.call(args, 1));
                }

                if (options === 'destroy') {
                    $.data(this, 'plugin_' + pluginName, null);
                }
            });

            return returns !== undefined ? returns : this;
        }
    };

}(jQuery, window, document));
