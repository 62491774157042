var geocoder;
var map;
function initMap() {
    var openInfoWindow,

        styles = [
            {
                'stylers': [
                    { 'saturation': -82 }
                ]
            }
        ],

        styledMap = new google.maps.StyledMapType(styles, {name: 'Styled Map'}),

        mapOptions = {
            zoom: 4,
            center: new google.maps.LatLng(-26.008786, 133.935305),
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
            },
            mapTypeControl: false,
            scrollwheel: false
        },

        map = new google.maps.Map(document.getElementById('map'), mapOptions);

    function infoWindow(marker, map, title, address, url) {
        google.maps.event.addListener(marker, 'click', function() {
            if(openInfoWindow)
                openInfoWindow.close();

            var html = '<h4>' + title + '</h4><p>' + address + '</p>';
            if(url) {
                html += '<p><a class="btn btn-primary btn-sm" href="' + url + '">View details <i class="fa fa-angle-right"></i></a></p>';
            }

            iw = new google.maps.InfoWindow({ content : html, maxWidth : 320});
            iw.open(map, marker);
            openInfoWindow = iw;
        });
    }

    function geocodeAddress(locations, i) {
        setTimeout(function() {
            var name = locations[i].name,
                address = locations[i].address,
                url = locations[i].url;

            geocoder.geocode({
                    'address': address
                },

                function (results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        var marker = new google.maps.Marker({
                            icon: icon,
                            map: map,
                            position: results[0].geometry.location,
                            title: name,
                            animation: google.maps.Animation.DROP,
                            address: address,
                            url: url
                        });

                        infoWindow(marker, map, name, address, numLocations > 1 ? url : null);

                        if (numLocations === 1) {
                            map.setCenter(results[0].geometry.location);
                            map.setZoom(15);
                        } else {
                            bounds.extend(marker.getPosition());
                            map.fitBounds(bounds);
                        }
                    } else {
                        console.log("geocode of " + address + " failed:" + status);
                    }
                });
        }, 300 * i);
    }

    function plotPoints(locations, i) {
        var location = {lat: Number(locations[i].lat), lng: Number(locations[i].long)},
            marker = new google.maps.Marker({
            icon: icon,
            map: map,
            position: location,
            title: locations[i].name,
            animation: google.maps.Animation.DROP,
            address: locations[i].address,
            url: locations[i].url
        });

        infoWindow(marker, map, locations[i].name, locations[i].address, numLocations > 1 ? locations[i].url : null);

        if (numLocations === 1) {
            map.setCenter(location);
            map.setZoom(15);
        } else {
            bounds.extend(marker.getPosition());
            map.fitBounds(bounds);
        }
    }

    //Associate the styled map with the MapTypeId and set it to display.
    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');

    // contactLocation is a global set in the map shortcode function, as is markerLocation
    if(contactLocation && contactLocation.length) {

        geocoder = new google.maps.Geocoder();
        var numLocations = contactLocation.length,
            icon = {
                url: markerLocation,
                // This marker is 20 pixels wide by 32 pixels high.
                size: new google.maps.Size(71, 76),
                // The origin for this image is (0, 0).
                origin: new google.maps.Point(0, 0),
                // The anchor for this image is the base of the flagpole at (0, 32).
                anchor: new google.maps.Point(44, 73)
            },
            locationAddress,
            locationName,
            locationUrl,
            i,
            bounds = new google.maps.LatLngBounds();

        for (i = 0; i < numLocations; i++) {
            if(contactLocation[i].lat != '' && contactLocation[i].long != '') {
                plotPoints(contactLocation, i);
            } else {
                geocodeAddress(contactLocation, i);
            }
        }
    }

    google.maps.event.addListener(map, 'mousedown', function(event) {
        this.setOptions({scrollwheel:true});
    });

    google.maps.event.addListener(map, 'mouseout', function(event) {
        this.setOptions({scrollwheel:false});
    });
}